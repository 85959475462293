export default [
  {
    path: '/monitors/office',
    name: 'monitors-office',
    component: () => import('@/views/monitors/office.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'The office monitor',
    },
  },
]
